import { useNavigate } from "react-router-dom"
import { pathToAdminDevice } from "../../utilities/paths";





export const useAdminDeviceNavigation = () => {
    const navigate = useNavigate();


    const navigateToAdminDevice = (serialNumber:string)=>{
        let path = pathToAdminDevice(serialNumber)
        navigate(path, { state: { internal: true } })
    }


    return navigateToAdminDevice
}